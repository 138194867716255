import { useEffect, useRef, useState } from 'react'

import { Edit as EditIcon } from '@mui/icons-material'
import {
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Typography,
  createTheme,
} from '@mui/material'

import { Account } from '../../../client'
import { colors } from '../../../theme/color'
import AccountAvatar from '../account-avatar'

const theme = createTheme()
const classes = {
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    width: '100%',
    height: '4rem',
  },
}

export type AccountListProps = {
  loading: boolean
  accounts: Array<Account>
  onSelectAccount?: (user: Account | undefined) => void | undefined
}

export default function AccountsList({
  loading,
  accounts,
  onSelectAccount,
}: AccountListProps): JSX.Element {
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>()

  const accountsRef = useRef<Array<Account> | undefined>(undefined)

  accountsRef.current = accounts

  useEffect(() => {
    if (onSelectAccount) {
      onSelectAccount(selectedAccount)
    }
  }, [selectedAccount])

  const accountList = accounts.map((account: Account, i: number) => {
    const name = account.name ?? `<No Name> - (${account.name})`
    const labelId = `checkbox-list-label-${account.name}`

    const selected = account.id === selectedAccount?.id

    return (
      <ListItem
        sx={classes.item}
        key={i}
        role={undefined}
        style={
          selected ? { backgroundColor: colors.secondary.A500 } : undefined
        }
        onClick={() => setSelectedAccount(account)}
      >
        <ListItemIcon>
          <AccountAvatar account={account} />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={
            <Typography
              style={{ userSelect: 'text' }}
              component="span"
              variant="body1"
            >
              {name}
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            style={{ marginLeft: 12 }}
            edge="end"
            aria-label="edit"
            href={`/accounts/${account.id}`}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  })

  const buildContent = () => {
    if (loading) {
      return [1, 2, 3, 4, 5].map((i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          animation="wave"
          style={{ height: '3rem', marginBottom: '0.5rem' }}
        />
      ))
    } else {
      if (accounts.length > 0) {
        return accountList
      }
    }

    return (
      <Card>
        <CardContent>No Accounts found</CardContent>
      </Card>
    )
  }

  const content = buildContent()

  return (
    <>
      <List sx={classes.root}>{content}</List>
    </>
  )
}
