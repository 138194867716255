import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../auth'

interface AuthRouteInterface {
  children: JSX.Element
}
export default function AuthRoute({
  children,
}: AuthRouteInterface): JSX.Element {
  const auth = useAuth()
  const navigate = useNavigate()

  if (!auth.activeSession()) {
    navigate('/')
  }

  return children
}
