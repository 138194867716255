import { useFormik } from 'formik'
import { useEffect } from 'react'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material'

import { Account } from '../../../client'
import { validationSchema } from './validate'

export type EditAccountDialogFields = Omit<Account, 'id'>

export type EditAccountDialogProps = {
  loading?: boolean
  account?: Account | undefined
  open?: boolean
  onCancel: () => void
  onAccept: (fields: EditAccountDialogFields) => Promise<void>
}

export default function EditAccountDialog({
  loading,
  account,
  open,
  onCancel,
  onAccept,
}: EditAccountDialogProps): JSX.Element {
  const initialValues: EditAccountDialogFields = {
    name: account?.name ?? '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onAccept,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  })

  useEffect(() => {
    resetForm()
  }, [open])

  const resetForm = () => {
    formik.resetForm({
      values: {
        name: account?.name ?? '',
      },
    })
  }

  const action = account ? 'Edit' : 'Create'

  return (
    <Dialog
      open={Boolean(open)}
      onClose={onCancel}
      aria-labelledby="edit-account-dialog"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="edit-account-dialog">{action} Account</DialogTitle>
        <DialogContent style={{ minHeight: '400px' }}>
          <DialogContentText>
            <Typography component="span" variant="body1">
              {action} account{' '}
              <Typography component="span" variant="h5">
                {account ? account.name : ''}
              </Typography>
            </Typography>
          </DialogContentText>
          <FormControl
            required
            fullWidth
            error={Boolean(formik.errors.name)}
            component="fieldset"
          >
            <TextField
              style={{ marginTop: '2ch', minWidth: '48ch' }}
              id="name"
              name="name"
              type="text"
              label="Name"
              fullWidth
              error={Boolean(formik.errors.name)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              inputProps={{
                maxLength: 128,
              }}
            />
            <FormHelperText>{formik.errors.name || ' '}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            type="reset"
            onClick={onCancel}
            variant="text"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            endIcon={loading ? <CircularProgress size={20} /> : undefined}
          >
            {action}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
