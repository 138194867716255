export const colors = {
  primary: {
    light: '#0254F0',
    dark: '#1B224B',
    A50: '#000F33',
    A400: '#0448CB',
  },
  secondary: {
    light: '#E9EBF0',
    dark: '#7682A1',
    A300: '#A3A8B5',
    A400: '#C4C9D7',
    A500: '#F2F5F8',
  },
  accent: {
    red: {
      light: '#E32645',
      dark: '#E12852',
    },
    yellow: {
      dark: '#E27600',
    },
    green: {
      light: '#46B082',
      dark: '#3B936A',
    },
  },
  boxShadow: {
    default: '#00000029',
  },
  login: {
    background: '#004DC0',
  },
  font: {
    default: {
      header: '#000F33',
      body: '#565F76',
    },
  },
  translucent: {
    blue: '#0064E127',
  },
  background: {
    white: 'white',
    primary: '#0056D5',
    gray: '#F5F7FA',
    lightblue: '#F2F6FC',
  },
  text: {
    white: 'white',
    red: 'red',
    paragraph: '#6B7592',
    secondary: '#606A84',
    noclue: '#152546',
  },
  border: {
    solid: '#C4C9D7',
  },
}
