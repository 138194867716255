/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback } from 'react'
import useSWR, { KeyedMutator } from 'swr'

import {
  Account,
  AccountApiClient,
  AccountList,
  CreateAccountRequestBody,
} from '../../client'
import useConfig from '../config/useConfig'

const accountKeys = {
  accounts: (): string => '/accounts',
}

export type AccountsHookResponse = {
  data: AccountList | undefined
  isError: boolean
  isLoading: boolean
  mutate: KeyedMutator<AccountList>
  addAccount: (account: CreateAccountRequestBody) => Promise<void>
  editAccount: (editAccount: Account) => Promise<void>
  deleteAccount: (id: string) => Promise<void>
}

export default function useAccounts(): AccountsHookResponse {
  const { config, isLoading, isError } = useConfig()

  if (isLoading || isError || config === undefined) {
    return {
      data: undefined,
      isLoading: isLoading,
      isError: isError,
      mutate: async () => undefined,
      addAccount: async () => {},
      editAccount: async () => {},
      deleteAccount: async () => {},
    }
  }
  const client = new AccountApiClient(config)

  const key = accountKeys.accounts

  const { data, error, mutate } = useSWR(key, () => client.getAccountList(), {
    revalidateOnMount: true,
  })

  const loading = !data

  const addAccount = useCallback(async (account: CreateAccountRequestBody) => {
    await client.createAccount(account)
    await mutate()
  }, [])

  const editAccount = useCallback(async (editAccount: Account) => {
    await client.editAccount(editAccount.id, {
      name: editAccount.name,
    })
    await mutate()
  }, [])

  const deleteAccount = useCallback(async (id: string) => {
    await client.deleteAccount(id)
    await mutate()
  }, [])

  return {
    data,
    isError: error,
    isLoading: loading,
    mutate: mutate,
    addAccount: addAccount,
    editAccount: editAccount,
    deleteAccount: deleteAccount,
  }
}
