import { BrowserRouter, Route, Routes } from 'react-router-dom'

import AuthProvider from './auth/AuthProvider'
import AccountPage from './pages/account/AccountPage'
import LandingPage from './pages/landing/LandingPage'
import LoginPage from './pages/login'

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/accounts" element={<LandingPage />} />
          <Route path="/accounts/:id" element={<AccountPage />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
