import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import { useAuth } from '../../auth'
import { colors } from '../../theme'
import { StyledGridItem, classes } from './styles'
import { LoginFormFields, validate } from './validate'

export default function LoginForm(): JSX.Element {
  const navigate = useNavigate()

  const auth = useAuth()
  const [error, setError] = useState({
    login: false,
  })

  const onSubmit = async ({ username, password }: LoginFormFields) => {
    try {
      setError({
        login: false,
      })

      const token = await auth.onLogin(username, password)

      if (token) {
        navigate('/accounts')
      } else {
        throw new Error('Unauthorized error')
      }
    } catch (err) {
      setError({
        login: true,
      })
      console.error(err)
    }
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    } as LoginFormFields,
    validate,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  })
  return (
    <Grid container sx={classes.root}>
      <StyledGridItem item xs={12}>
        <img src="/ab_logo.png" alt="applyboard-logo" width="242" height="37" />
        <Typography variant="h4" component="h1" sx={classes.loginHeader}>
          Sign In
        </Typography>
        <Typography variant="body1">Continue to the Demo Portal</Typography>
      </StyledGridItem>
      <StyledGridItem item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <StyledGridItem item xs={12}>
              <TextField
                sx={classes.input}
                fullWidth
                label="Email"
                id="username"
                name="username"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.username}
              />
              {formik.errors.username ? (
                <Typography
                  component="p"
                  variant="caption"
                  style={{ color: colors.accent.red.dark }}
                >
                  {formik.errors.username}
                </Typography>
              ) : null}
            </StyledGridItem>
            <StyledGridItem item xs={12}>
              <TextField
                sx={classes.input}
                fullWidth
                label="Password"
                id="password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password ? (
                <Typography
                  component="p"
                  variant="caption"
                  style={{ color: colors.accent.red.dark }}
                >
                  {formik.errors.password}
                </Typography>
              ) : null}
              <Button
                style={{ marginTop: 16 }}
                type="submit"
                endIcon={
                  formik.isSubmitting ? (
                    <CircularProgress color="primary" size={16} />
                  ) : undefined
                }
              >
                Sign In
              </Button>
              {error.login ? (
                <React.Fragment>
                  <Typography
                    style={{
                      marginTop: 8,
                      lineHeight: '1rem',
                      color: colors.accent.red.dark,
                    }}
                    variant="caption"
                    component="p"
                  >
                    Incorrect username or password. If you’ve forgotten your
                    password, send an email to support@applyproof.com for a
                    password reset
                  </Typography>
                </React.Fragment>
              ) : undefined}
            </StyledGridItem>
          </Grid>
        </form>
      </StyledGridItem>
    </Grid>
  )
}
