import { Box, Container, Grid, styled } from '@mui/material'

import { colors } from '../../theme'

export const RootContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    paddingTop: '20vh',
  },
  [theme.breakpoints.down('xs')]: {
    paddingTop: 0,
    padding: '3rem',
  },
  backgroundColor: colors.login.background,
}))

export const LoginFormGridItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minWidth: 530,
  },
  [theme.breakpoints.down('xs')]: {
    margin: '3rem',
  },
}))

export const RootPage = styled(Box)({
  backgroundColor: colors.login.background,
  minHeight: '100vh',
})
