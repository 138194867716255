import axios from 'axios'
import useSWR from 'swr'

const configKeys = {
  getConfig: () => '/config',
}

export type Configuration = {
  namespaceDomainName: string
  userPoolId: string
  userPoolClientId: string
}

export default function useConfig() {
  const key = configKeys.getConfig()

  const fetcher = async () => {
    const response = await axios.get<Configuration>('/config/config.json')

    return response.data
  }

  const { data, error, mutate } = useSWR(key, fetcher, {
    revalidateOnMount: true,
  })

  const loading = !data

  return {
    config: data,
    isError: error,
    isLoading: loading,
    mutate: mutate,
  }
}
