/**
 * Take an objects key (ex. firstName) and formats it into human readable label (ex. First Name)
 *
 * Ex. firstName --> First Name
 */
export function formatLabelFromPropKey(key: string): string {
  // Searches for the first shift from lowercase to uppercase to inject a space
  return (key.charAt(0).toUpperCase() + key.slice(1)).replace(
    /([a-z])([A-Z])/g,
    '$1 $2'
  )
}
