import React, { useState } from 'react'

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material'
import { Box, Button, Grid, TextField, Tooltip } from '@mui/material'

import { CreateAccountRequestBody } from '../../client'
import useAccounts from '../../hooks/accounts/useAccounts'
import EditAccountDialog from '../account/edit-account/EditAccountDialog'
import AppAlert from '../app-alert'
import { AlertState } from '../app-alert/AppAlert'

const classes = {
  root: {
    display: 'flex',
  },
}

export type AccountActionBarProps = {
  onAccountCreated?: () => void
  onNameFilter?: (filter: string) => void
  setShowEnabled?: (showEnabled: boolean) => void
  setShowDisabled?: (showDisabled: boolean) => void
}

export default function ActionBar({
  onNameFilter = () => void 0,
}: AccountActionBarProps): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    type: 'success',
    message: '',
  })

  const { addAccount } = useAccounts()

  const handleCreateCancel = () => {
    setCreateOpen(false)
  }

  const handleCreateAccept = async (
    fields: CreateAccountRequestBody
  ): Promise<void> => {
    console.log('accepted')

    try {
      setLoading(true)
      await addAccount(fields)

      setAlertState({
        open: true,
        type: 'success',
        message: 'Successfully created account',
      })
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.stack)
      } else {
        console.error(err)
      }
      setAlertState({
        open: true,
        type: 'error',
        message: 'Failure to create account',
      })
    } finally {
      setLoading(false)
      setCreateOpen(false)
    }
  }

  const handleAlertClose = () => {
    setAlertState({
      open: false,
      type: alertState.type,
      message: alertState.message,
    })
  }

  const [nameFilter, setNameFilter] = useState('')

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value)
    onNameFilter(e.target.value)
  }

  return (
    <Box sx={classes.root}>
      <AppAlert {...alertState} onClose={handleAlertClose} />
      <EditAccountDialog
        loading={loading}
        open={createOpen}
        onCancel={handleCreateCancel}
        onAccept={handleCreateAccept}
      />
      <Grid container spacing={3} alignContent="center" alignItems="center">
        <Grid item>
          <TextField
            label="Search..."
            value={nameFilter}
            onChange={handleFilterChange}
          ></TextField>
        </Grid>
        <Grid item>
          <Tooltip title="Create new account">
            <Button
              startIcon={<AddCircleOutlineIcon />}
              variant="text"
              onClick={() => setCreateOpen(true)}
            >
              Create
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  )
}
