import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material'
import {
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'

import AccountAvatar from '../../components/account/account-avatar'
import AccountDetails from '../../components/account/account-details/AccountDetails'
import DeleteAccountDialog from '../../components/account/delete-account/DeleteAccountDialog'
import EditAccountDialog, {
  EditAccountDialogFields,
} from '../../components/account/edit-account/EditAccountDialog'
import AppAlert, { AlertState } from '../../components/app-alert/AppAlert'
import AuthRoute from '../../components/auth-route'
import Nav from '../../components/nav'
import useAccount from '../../hooks/account/useAccount'
import useAccounts from '../../hooks/accounts/useAccounts'

type AccountPageParams = {
  id: string
}

export default function AccountPage(): JSX.Element {
  const { id } = useParams<AccountPageParams>()
  const [editOpen, setEditOpen] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const navigate = useNavigate()

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    type: 'success',
    message: '',
  })

  const handleEditAccount = () => {
    setEditOpen(true)
  }

  const { deleteAccount } = useAccounts()

  const { data, isLoading, isError, editAccount } = useAccount(id ?? '')

  let content
  if (isError) {
    content = (
      <Typography variant="h2" component="h2">
        Account not found
      </Typography>
    )
  } else if (data) {
    const account = data

    const handleEditAccept = async (
      fields: EditAccountDialogFields
    ): Promise<void> => {
      try {
        if (!id) {
          return
        }
        setEditLoading(true)
        await editAccount({ id, ...fields })

        setAlertState({
          open: true,
          type: 'success',
          message: 'Successfully updated account',
        })
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.stack)
        } else {
          console.error(err)
        }
        setAlertState({
          open: true,
          type: 'error',
          message: 'Failure to update account',
        })
      } finally {
        setEditLoading(false)
        setEditOpen(false)
      }
    }

    const handleDeleteAccount = async () => {
      setDeleteLoading(true)
      try {
        await deleteAccount(account.id)
        navigate('/accounts')

        setAlertState({
          open: true,
          type: 'success',
          message: 'Successfully deleted account',
        })
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.stack)
        } else {
          console.error(err)
        }
        setAlertState({
          open: true,
          type: 'error',
          message: 'Failure to delete account',
        })
      } finally {
        setDeleteLoading(false)
        setDeleteOpen(false)
      }
    }

    content = (
      <>
        <EditAccountDialog
          loading={editLoading}
          open={editOpen}
          account={account}
          onCancel={() => setEditOpen(false)}
          onAccept={handleEditAccept}
        />
        <DeleteAccountDialog
          loading={deleteLoading}
          username={account.name}
          open={deleteOpen}
          onCancel={() => setDeleteOpen(false)}
          onAccept={handleDeleteAccount}
        />
        <Grid container spacing={3}>
          <Grid container spacing={2} alignItems="center" alignContent="center">
            <Grid item style={{ paddingLeft: 24 }}>
              <AccountAvatar account={account} />
            </Grid>
            <Grid item>
              <Typography variant="h1" component="h1" style={{ marginTop: 16 }}>
                {account?.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <Tooltip title="Edit Account">
                  <IconButton aria-label="edit" onClick={handleEditAccount}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Delete Account">
                  <IconButton
                    aria-label="delete"
                    onClick={() => setDeleteOpen(true)}
                  >
                    <DeleteIcon sx={{ color: 'red' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Paper style={{ padding: '3rem' }}>
              <Typography variant="h5" component="h5">
                Account Details
              </Typography>
              <AccountDetails account={account} />
            </Paper>
          </Grid>
        </Grid>
      </>
    )
  } else {
    content = <></>
  }

  const handleAppalertClose = () => {
    setAlertState({ ...alertState, open: false })
  }

  return (
    <AuthRoute>
      <Container maxWidth="lg">
        <AppAlert {...alertState} onClose={handleAppalertClose} />
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ paddingTop: 48 }}>
            <Nav loading={isLoading} />
          </Grid>
          <Grid item xs={12}>
            <IconButton
              onClick={() => navigate(-1)}
              style={{ marginBottom: 40 }}
            >
              <ArrowBackIcon />
            </IconButton>
            {content}
          </Grid>
        </Grid>
      </Container>
    </AuthRoute>
  )
}
