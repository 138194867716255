import Axios, { AxiosInstance } from 'axios'

import { AuthClient } from '../auth/AuthClient'
import { LocalCognitoStorage } from '../auth/LocalCognitoStorage'
import { Configuration } from '../config'

export async function createAuthedClient(
  config: Configuration
): Promise<AxiosInstance> {
  const auth = new AuthClient(config)
  await auth.refreshIfExpired()

  const storage = new LocalCognitoStorage()
  const id = storage.getItem('idToken')

  return Axios.create({
    // withCredentials: true,
    headers: {
      Authorization: `Bearer ${id}`,
    },
  })
}
