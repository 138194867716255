import React, { Fragment } from 'react'

import {
  Close as CloseIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  createTheme,
} from '@mui/material'

import { Account } from '../../../client'
import AccountAvatar from '../account-avatar'
import AccountDetails from '../account-details/AccountDetails'

const theme = createTheme()
const classes = {
  root: {},
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}

export type QuickAccountInfoProps = {
  loading?: boolean
  account: Account | undefined
  onClose: () => void
}

export default function QuickAccountInfo({
  account,
  onClose,
}: QuickAccountInfoProps): JSX.Element {
  const cardContent = account ? (
    <Fragment>
      <CardHeader
        avatar={<AccountAvatar account={account} />}
        title={account.name}
        subheader={account.id}
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <AccountDetails account={account} />
      </CardContent>
      <CardActions disableSpacing>
        <Grid container spacing={1}>
          <Grid item style={{ flex: 2 }}></Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              endIcon={<OpenInNewIcon />}
              href={`/accounts/${encodeURIComponent(account.id)}`}
            >
              View Account
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Fragment>
  ) : undefined

  return <Card sx={classes.root}>{cardContent}</Card>
}
