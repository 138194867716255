import { Grid } from '@mui/material'

import LoginForm from '../../components/login-form'
import * as S from './styles'

// import { LoginFormGridItem, RootContainer } from './styles'

export default function LoginPage(): JSX.Element {
  return (
    <S.RootPage>
      <S.RootContainer maxWidth="xl">
        <Grid item container>
          <Grid item xs={12} lg={1}></Grid>
          <S.LoginFormGridItem item xs={12} md={3} lg={3}>
            <LoginForm />
          </S.LoginFormGridItem>
        </Grid>
      </S.RootContainer>
    </S.RootPage>
  )
}
