import { Configuration } from '../hooks/config/useConfig'
import { createAuthedClient } from './createAuthClient'
import {
  Account,
  AccountList,
  CreateAccountRequestBody,
  GetAllAccountsResponse,
  UpdateAccountBody,
} from './types'

export class AccountApiClient {
  constructor(private readonly config: Configuration) {}

  host = `https://api.account.${this.config.namespaceDomainName}`

  async getAccountList(): Promise<AccountList> {
    const client = await createAuthedClient(this.config)
    const url = `${this.host}/v1/account`
    const response = await client.get<GetAllAccountsResponse>(url)

    return response.data.accounts
  }

  async getAccount(id: string): Promise<Account> {
    const client = await createAuthedClient(this.config)

    const url = `${this.host}/v1/account/${id}`
    const response = await client.get<Account>(url)

    return response.data
  }

  async createAccount(account: CreateAccountRequestBody): Promise<string> {
    const client = await createAuthedClient(this.config)

    const url = `${this.host}/v1/account`
    const response = await client.post<string>(url, account)

    return response.data
  }

  async deleteAccount(id: string): Promise<void> {
    const client = await createAuthedClient(this.config)

    const url = `${this.host}/v1/account/${id}`

    await client.delete(url)
  }

  async editAccount(id: string, account: UpdateAccountBody): Promise<void> {
    const client = await createAuthedClient(this.config)

    const url = `${this.host}/v1/account/${id}`

    await client.patch(url, account)
  }
}
