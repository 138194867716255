/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback } from 'react'
import useSWR, { KeyedMutator } from 'swr'

import { Account, AccountApiClient } from '../../client'
import useConfig from '../config/useConfig'

const accountKeys = {
  getAccount: (id: string): string => `/account/${id}`,
}

export type AccountHookResponse = {
  data: Account | undefined
  isError: boolean
  isLoading: boolean
  mutate: KeyedMutator<Account>
  editAccount: (editAccount: Account) => Promise<void>
  deleteAccount: (id: string) => Promise<void>
}

export default function useAccount(id: string): AccountHookResponse {
  const { config, isLoading, isError } = useConfig()

  if (isLoading || isError || config === undefined) {
    return {
      data: undefined,
      isLoading: isLoading,
      isError: isError,
      mutate: async () => undefined,
      editAccount: async () => {},
      deleteAccount: async () => {},
    }
  }

  const client = new AccountApiClient(config)

  const key = accountKeys.getAccount(id)

  const fetcher = async () => {
    const accountData = await client.getAccount(id)

    return accountData
  }
  const { data, error, mutate } = useSWR(key, fetcher, {
    revalidateOnMount: true,
  })
  const loading = !data

  const editAccount = useCallback(async (editAccount: Account) => {
    await client.editAccount(editAccount.id, {
      name: editAccount.name,
    })
    await mutate()
  }, [])

  const deleteAccount = useCallback(async (id: string) => {
    await client.deleteAccount(id)
    await mutate()
  }, [])

  return {
    data,
    isError: error,
    isLoading: loading,
    mutate: mutate,
    editAccount: editAccount,
    deleteAccount: deleteAccount,
  }
}
