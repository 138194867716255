import React, { useState } from 'react'

import { Container, Grid, Pagination } from '@mui/material'

import { Account } from '../../client'
import AccountsList from '../../components/account/account-list'
import QuickAccountInfo from '../../components/account/quick-info/QuickAccountInfo'
import ActionBar from '../../components/action-bar/ActionBar'
import AuthRoute from '../../components/auth-route/AuthRoute'
import Nav from '../../components/nav'
import useAccounts from '../../hooks/accounts/useAccounts'

export default function LandingPage(): JSX.Element {
  const [filter, setFilter] = useState<string>('')
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(
    undefined
  )

  const [page, setPage] = useState(1)
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
  }

  const accountsPerPage = 10

  const { data, isLoading, mutate } = useAccounts()

  const loading = isLoading

  const accountsList =
    data
      ?.filter((account: Account) =>
        /** Find case-insensitive name matches */
        account.name.toLowerCase().includes(filter.toLowerCase())
      )
      /** Sort accounts alphabetically by name */
      .sort((a, b) => ('' + a.name).localeCompare('' + b.name)) ?? []

  const numPages = accountsList
    ? Math.ceil(accountsList.length / accountsPerPage)
    : 0

  const accounts = (
    <AccountsList
      loading={loading}
      accounts={accountsList.slice(
        (page - 1) * accountsPerPage,
        page * accountsPerPage
      )}
      onSelectAccount={(account) => {
        account ? setSelectedAccount(account) : setSelectedAccount(undefined)
      }}
    />
  )

  const quickAccountInfoClose = () => {
    setSelectedAccount(undefined)
  }

  const quickAccountInfo = (
    <QuickAccountInfo
      account={selectedAccount}
      onClose={quickAccountInfoClose}
    />
  )

  return (
    <AuthRoute>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ paddingTop: 48 }}>
            <Nav loading={loading} />
          </Grid>
          <Grid item xs={12}>
            <ActionBar
              onAccountCreated={() => mutate}
              onNameFilter={(filter) => {
                setFilter(filter)
                setPage(1)
              }}
            />
          </Grid>
          <Grid item xs={7}>
            {accounts}
          </Grid>
          <Grid item xs={5}>
            {quickAccountInfo}
          </Grid>
          <Grid item xs={7}>
            <Pagination
              style={{
                paddingTop: '0.15rem',
                paddingBottom: '3rem',
              }}
              count={numPages}
              page={page}
              onChange={handlePageChange}
            />
          </Grid>
        </Grid>
      </Container>
    </AuthRoute>
  )
}
