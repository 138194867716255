import React, { Fragment } from 'react'

import { Button, Grid, LinearProgress, Typography } from '@mui/material'

import { useAuth } from '../../auth'

export type NavProps = {
  loading?: boolean
}

export default function Nav({ loading }: NavProps): JSX.Element {
  const auth = useAuth()

  const handleLogout = async () => {
    if (auth) {
      await auth.onLogout()
    } else {
      console.debug('unable to logout properly')
    }
  }

  return (
    <Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="center" paddingTop="24px">
            <Grid item style={{ marginBottom: '8px' }}>
              <img
                src="/ab_logo.png"
                alt="applyboard-logo"
                width="163"
                height="25"
              />
            </Grid>
            <Grid item style={{ marginTop: -8 }}>
              <Typography component="span" variant="h4">
                Demo Account Portal
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          alignItems="center"
          style={{ backgroundColor: '#FAFAFA', borderRadius: 5 }}
        >
          <Grid item>
            <Button href="/accounts" variant="text">
              Accounts
            </Button>
          </Grid>
          <Grid item>
            <Button href="/" variant="text" onClick={handleLogout}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <LinearProgress style={{ visibility: loading ? 'visible' : 'hidden' }} />
    </Fragment>
  )
}
