import { Typography } from '@mui/material'

import { Account } from '../../../client'
import { formatLabelFromPropKey } from '../../../utils/formatLabel'

export type AccountDetailsProps = {
  account: Account
}

export default function AccountDetails({
  account,
}: AccountDetailsProps): JSX.Element {
  const accountDetails = Object.entries(account)
    .sort()
    .map(([key, value]) => {
      /**
       * Take property name thats camel cased and convert it to human readable form.
       *
       * Ex. firstName --> First Name
       */
      const label = formatLabelFromPropKey(key)

      return (
        <Typography
          key={key}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {label}: {value}
        </Typography>
      )
    })

  return <>{accountDetails}</>
}
