import React from 'react'

import { Avatar } from '@mui/material'
import { green } from '@mui/material/colors'

import { Account } from '../../../client'

export type AccountAvatarProps = {
  account: Account | undefined
}

export default function AccountAvatar({
  account,
}: AccountAvatarProps): JSX.Element {
  const backgroundColor = green[500]
  return (
    <Avatar
      aria-label="profile"
      alt={account?.name ?? ''}
      style={{ backgroundColor }}
    >
      {account?.name ? account?.name[0] ?? '' : ''}
    </Avatar>
  )
}
